<template>
  <gf-base>
    <gf-card title="Transaction List" subtitle="List of Companies">
      <template #header>
        <el-input style="width: 250px" class="mr-20" size="medium" prefix-icon="el-icon-search" placeholder="Search by name or company code ..." v-model="search"></el-input>
        <span class="f-13 mr-10">Status :</span>
        <el-select size="medium" class="mr-20" v-model="status" @change="statusChange">
          <el-option :value="null" label="All"></el-option>
          <el-option :value="0" label="Inactive"></el-option>
          <el-option :value="1" label="Active"></el-option>
        </el-select>
        <span class="f-13 mr-10">Type :</span>
        <el-select size="medium" v-model="type" @change="typeChange">
          <el-option :value="null" label="All"></el-option>
          <el-option :value="1" label="Consumer"></el-option>
          <el-option :value="2" label="Business Customer"></el-option>
          <el-option :value="3" label="Supplier"></el-option>
        </el-select>
      </template>
      <gf-table :data="companies" :column-filter="columnFilter" @row-click="readCompany">
        <el-table-column v-if="columnFilter[0].show" label="Name" min-width="200" prop="name">
          <template #default="slot">
            {{ $StringFormat(slot.row.name, 20) }}
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[1].show" label="Company Code" min-width="200" prop="code">
        </el-table-column>
        <el-table-column v-if="columnFilter[2].show" label="Company Type" min-width="200">
          <template #default="slot">
            <span v-if="slot.row.type === 1">Business Customer</span>
            <span v-else-if="slot.row.type === 2">Consumer</span>
            <span v-else-if="slot.row.type === 3">Supplier</span>
            <span v-else>None</span>
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[3].show" label="Customer Group" min-width="200" prop="customer_group.name">
        </el-table-column>
        <el-table-column v-if="columnFilter[4].show" label="Email Address" min-width="200"  prop="email">
        </el-table-column>
        <el-table-column v-if="columnFilter[5].show" label="Phone Number" min-width="200"  prop="phone_number">
        </el-table-column>
        <el-table-column v-if="columnFilter[6].show" label="Fax" min-width="200"  prop="fax_number">
        </el-table-column>
        <el-table-column v-if="columnFilter[7].show" label="Website" min-width="200"  prop="website">
        </el-table-column>
        <el-table-column v-if="columnFilter[8].show" label="Status" min-width="120">
          <template #default="slot">
            <gf-label inline pill light type="primary" v-if="slot.row.is_active">Active</gf-label>
            <gf-label inline pill light type="dark" v-else>Inactive</gf-label>
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[9].show" label="Created" min-width="150"  prop="created_at">
          <template #default="slot">
            {{ $DateFormat(slot.row.created_at) }}
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[10].show" label="Last Updated" min-width="150" prop="updated_at">
          <template #default="slot">
            {{ $DateFormat(slot.row.updated_at) }}
          </template>
        </el-table-column>
      </gf-table>
      <template #footer>
        <gf-pagination :pagination="pagination" @change="getCompanies"></gf-pagination>
      </template>
    </gf-card>
  </gf-base>
</template>

<script>
// Services
import CompanyService from '@/services/v1/Company'

// Lodash
import { debounce } from 'lodash'

export default {
  data () {
    return {
      columnFilter: [
        { label: 'Name', show: true },
        { label: 'Company Code', show: true },
        { label: 'Company Type', show: true },
        { label: 'Customer Group', show: true },
        { label: 'Email Address', show: true },
        { label: 'Phone Number', show: true },
        { label: 'Fax', show: true },
        { label: 'Website', show: true },
        { label: 'Status', show: true },
        { label: 'Created', show: true },
        { label: 'Last Updated', show: true }
      ],

      // filter
      search: '',
      status: null,
      type: null,
      filter: {},

      // main
      companies: [],
      loading: false,

      // Pagination
      pagination: {
        total: 0,
        page: {
          size: 25,
          number: 1
        }
      }
    }
  },
  watch: {
    search: debounce(function (value) {
      if (value === '') {
        delete this.filter.q
      } else {
        this.filter.q = {
          like: value
        }
      }

      // Reset to page 1
      this.pagination.page.number = 1
      this.getCompanies()
    }, 400)
  },
  methods: {
    // Filter
    statusChange () {
      // Reset to page 1
      this.pagination.page.number = 1

      if (this.status !== null) {
        this.filter['cus.is_active'] = { '=': this.status }
        this.getCompanies()
        return
      }

      delete this.filter['cus.is_active']
      this.getCompanies()
    },
    typeChange () {
      // Reset to page 1
      this.pagination.page.number = 1

      if (this.type !== null) {
        this.filter['cus.type'] = { '=': this.type }
        this.getCompanies()
        return
      }

      delete this.filter['cus.type']
      this.getCompanies()
    },

    // autocomplete
    selectCustomerGroup (customerGroup) {
      this.selectedCustomerGroup = customerGroup.detail
    },

    // Fetch
    async getCompanies () {
      try {
        this.loading = true
        const cmService = new CompanyService()
        const result = await cmService.list(this.pagination.page, null, this.filter, null)
        this.companies = result.data.rows

        if (result.data.count === 0 && result.data.rows.length !== 0) {
        } else this.pagination.total = result.data.count
      } catch (error) {
        this.$Error(error)
      } finally {
        this.loading = false
      }
    },

    // handler
    readCompany (row) {
      this.$router.push({ name: 'transaction-view', params: { id: row.id } })
    }
  },
  mounted () {
    this.$store.dispatch(this.$SET_BREADCRUMB, [
      { title: 'Transactions', route: '' },
      { title: 'Companies' }
    ])

    this.getCompanies()
  }
}
</script>
