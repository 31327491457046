import Service from '../Service'

export default class extends Service {
  constructor (id) {
    const url = ['relationship', 'companies']
    if (id) {
      url.push(id)
    }
    super(url)
  }

  setActive (data) {
    return this.post(data, '/active')
  }

  setInactive (data) {
    return this.post(data, '/inactive')
  }
}
